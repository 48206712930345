.card {
    padding: 1rem;
    border-width: .15rem;
    border-style: solid;
    border-color: $text;
    box-shadow: 0px 0px 1rem rgba(#000, 0.25);
}
.card--blue {
    border-color: $headline;
    background: lighten($headline, 50%);
}