.main {
  // intro
  .intro-text {
    h1 {
      text-align: center;
      font-weight: 300;
      text-transform: uppercase;
      color: $headline;

      @media only screen and (min-width:1024px) {
        font-size: 4em;
      }

    }
  }

  // hero
  .hero {
    height: 200px;
    background: url('../img/hero.jpg') top center no-repeat;
    background-size: cover;
    margin-top: 25px;
    margin-bottom: 25px;
    @media only screen and (min-width:768px) {
      height: 450px;
    }
  }
}
