  // full width image
@mixin fullwidthimg {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

// circle button
@mixin circlebtn {
  display: inline-block;
  background: $circle;
  padding: 20px;
  color: white;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  height: 130px;
  width: 130px;
  vertical-align: top;
  line-height: 90px;
  border-radius: 130px;

  .wrapper {
    display: inline-block;
    vertical-align: middle;
    line-height: 1em;
  }

  span {
    font-size: 0.75em;
    display: block;
  }

  // hover
  &:hover,
  &:active {
    background: $circle-hover;
  }

  // large screens
  @media only screen and (min-width:768px) {
    font-size: 2.7em;
    height: 300px;
    width: 300px;
    vertical-align: top;
    line-height: 260px;
    border-radius: 200px;

    .wrapper {
      line-height: 1.2em;
    }

    span {
      font-size: 0.25em;
      line-height: 2.25em;
    }

  }
}

// button
@mixin button {
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background: $button;
  margin-top: .5em;
  margin-bottom: .5em;
  display: inline-block;

  &:hover,
  &:active {
    background: $button-hover;
  }
}

// callout
@mixin callout {
  padding: 20px;
  background: $callout;

  h1,h2,h3,h4,h5,h6 {
    text-align: center;
    color: $headline;
    text-transform: uppercase;
    font-weight: 400;
  }
  p  {
    a {
      color: $headline;

      &:link,
      &:visited {
        color: $headline;
      }

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

}

// img-grid
@mixin img-grid {
  .row {
    margin-bottom: 30px;
    div.bg {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      &.bg-1 {
        background-image: url('../img/plates/plate_01.jpg');
      }
      &.bg-2 {
        background-image: url('../img/plates/plate_02.jpg');
      }
      &.bg-3 {
        background-image: url('../img/plates/plate_03.jpg');
      }
      &.bg-4 {
        background-image: url('../img/plates/plate_04.jpg');
      }
      &.bg-5 {
        background-image: url('../img/plates/plate_05.jpg');
      }

    }
    &.row-1 {
      div.bg {
        height: 300px;
      }
    }
    &.row-2 {
      div.bg {
        height: 135px;
      }
    }
  }
}

@mixin visuallyhidden {
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}