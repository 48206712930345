::selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}

// custom
.fullwidthimg {
  @include fullwidthimg;
}
.circle-btn {
  @include circlebtn;
  // position
  &.top-left {
    float: left;
    transform: rotate(-15deg);
  }
  &.top-right {
    float: right;
    transform: rotate(15deg);
  }
}
.relative {
  position: relative;
  z-index: 1;
}
.absolute {
  position: absolute;
  z-index: 2;
}
.clear-both {
  clear: both;
}
.callout {
  @include callout;
}
.center {
  text-align: center;
}
.img-grid {
  @include img-grid;
}
.small-text {
  font-size: 1.25rem;
}