* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 62.5%;
  background: #fff;
}
body {
  font: 300 16px/1.6 'Alegreya Sans', sans-serif;
  color: #444;
  transition: all .4s .3s ease-in;
}
body,html{
	min-height: 100vh;
}
