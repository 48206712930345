$base : #F7F6F0;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);

// custom
$text: #414042;
$headline: #0078c2;
$callout: #cfe4f2;
$circle: #eaa949;
$circle-hover: $headline;
$button: $headline;
$button-hover: $circle;
$footer: #0073bc;
