.footer {
  color: #fff;

  a {
    &:link,
    &:visited {
      color: #fff;
      text-decoration: none;
    }
    &:hover,
    &:active {
      color: #fff;
      text-decoration: underline;
    }
  }
  .footer-content {
    background-color: $footer;
  }
  .footer-logo-link {
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1em;

    img {
      margin: 0 auto;
      padding: 10px 0;
    }
  }

  .facebook {
    clear: both;
    display: inline-block;
    text-transform: uppercase;
    margin: 10px 0;
    text-align: left;
    min-width: 320px;

    img {
      float: left;
      padding-right: 20px;
    }
  }
}

// mail chimp
#mc_embed_signup {
  h2 {
    font-weight: 200 !important;
    text-transform: uppercase;
    text-align: center;
  }

  input[type="submit"] {
    @include button;
    height: auto;
    background-color: $circle;
    line-height: inherit;
    border-radius: 0;
    font-size: inherit;

    &:hover,
    &:active {
      background-color: darken($circle, 15%);
    }
  }

  .response {
    color: #fff !important;
    text-align: center;
  }
  .mc-field-group {
    text-align: center;
  }
}
