/* social media menu */
.social-nav {
    text-align: center;
}
.social-nav__link {
    display: inline-block;
    padding: 1rem;
    color: $headline;

    &:hover,
    &:active {
        color: $circle;
    }
}
.social-nav__link__text {
    @include visuallyhidden;
}