.header {

  img {
    display: none;
  }

  // wrapper
  .absolute.bg {
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    position: static;
    background: url('../img/header.png') top center no-repeat;
    background-size: cover;
  }
  // license-plate
  .license-plate {
    padding-top: 50px;
    text-align: center;

    img {
      max-width: 100%;
      width: 220px;
      height: auto;
      padding: 0;
      margin: 0;
      box-shadow: 0 0 5px 5px rgba(#000, 0.25);
      border-radius: 16px;
      display: inline;

      @media only screen and (min-width:768px) {
        max-width: auto;
        width: auto;
      }
    }
  }

  // large screens
  @media only screen and (min-width:1430px) {
    // show inline bg
    img {
      display: block;
    }
    // wrapper
    .absolute.bg {
      position: absolute;
      background: none;
    }
  }

}
